    <ng-container *ngIf="currentpage$ | async as page">
        <div class="container-fluid my-1" *ngIf="page===1">
            <div class="my-3">
                <div class="col px-1">
                    <button class="btn btn-outline-download" (click)="exportToExcel()">
                        <i class="fa fa-file" height="14px" width="16px" aria-hidden="true"></i>{{"bulkOrder.downloadExcelSheet" |cxTranslate}}
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
